@import "../../../global";

.ProtectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100dvh;
    width: 100%;

    .background {
        display: none;

        @media (max-width: 991px) {
            display: block;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100dvh;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.301);

            // background-color: $mainBgColor;
        }
    }

    .menu {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        overflow: overlay;

        // padding: 0 15px;
        // background-color: #0D0D1C;
        background-color: $navAndSidebarColor;
        transition: all 0.3s;
        color: $primaryColor;

        @media (max-width: 991px) {
            position: absolute;
            left: -300px;
            z-index: 10;
        }

        &.collapsed {
            width: 300px;
            left: 0;
        }

        &.notCollapsed {
            width: 90px;
            align-items: center;
        }

        .drawer {
            display: none;
            text-align: center;
            width: 100%;
            height: 20px;
            margin: 25px auto 50px;

            span {
                cursor: pointer;
                background-color: transparent;
                font-size: 25px;
            }

            &.collapsed {
                text-align: right;
            }

            @media (max-width: 991px) {
                display: block;
            }
        }

        .logo {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            padding: 25px 12%;

            // min-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.1s;

            &.notCollapsed {
                padding: 8px;

                .name,
                .profileButton {
                    visibility: hidden;
                }
            }

            img {
                // height: 60px;
                // max-height: 80px;
                // max-width: 80px;
                // border-radius: 100%;
                // width: 100%;
                cursor: pointer;

                // aspect-ratio: 2;

                &.imageNotCollapsed {
                    max-width: 60px;
                    max-height: 60px;
                }
            }

            .name {
                width: 100%;
                font-size: 16px;
                margin: 8px 0;
                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;

                // white-space: nowrap;
           
            }

            .profileButton {
                padding: 8px 25px;
                white-space: nowrap;
                color: white;
                border-radius: 5px;
                background-color: #181829;
                font-size: 12px;
                border: none;

                &:focus-visible,
                &:focus-within {
                    outline: none;
                }
            }

            // @media (max-width : 991px) {
            //     display: none;
            // }
       
        }

        .item {
            width: 100%;
            height: 60px;
            margin: 2px 0;
            padding: 18px 24px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            color: $primaryColor;

            // font-weight: 600;
            font-size: 14px;

            &.active {
                // background-color: #181829;
                // background-color: $mainBgColor;
                background-color: $primaryColorWithAlpha;

                // border-radius: 15px;
                color: $primaryColor;
                font-weight: 500;

                // border-right: 5px solid #0AA7FF;
                border-right: 5px solid $primaryColor;
            }

            p {
                margin: 0 10px;
                white-space: nowrap;
            }
        }
    }

    .routeContent {
        position: relative;
        width: 100%;

        // background-color: #181829;
        background-color: $mainBgColor; // todo:
        display: flex;
        flex-direction: column;
        height: 100dvh;
        overflow: hidden;

        .content {
            width: 100%;
            height: -webkit-fill-available;

            // display: flex;
            overflow: auto;
            padding: 20px;
        }
    }
}
